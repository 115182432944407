var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "", "grid-list-xl": "" } },
    [
      _c(
        "v-layout",
        {
          attrs: {
            "align-center": "",
            "justify-center": "",
            row: "",
            "fill-height": "",
          },
        },
        [
          _c(
            "v-flex",
            [
              _c(
                "material-card",
                { attrs: { color: "primary", title: "Proceso" } },
                [
                  _c(
                    "v-form",
                    { ref: "branchForm" },
                    [
                      _c(
                        "v-layout",
                        [_c("FilterData", { on: { search: _vm.search } })],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    [_c("ProccessList", { ref: "proccessList" })],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }