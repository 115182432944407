import FilterData from '../../components/FilterData/Filter.vue'
import ProccessList from './ProccessList.vue'
export default {
    data() {
        return {
           data: [
               {
                   date: ''
            }
           ] 
        }
    },
    methods: {
        search (data) {
            this.$refs.proccessList.searchData(data)
        }
    },
    components: {
        FilterData,
        ProccessList
    }
}